const OrdersBonusPermissionEnum = Object.freeze({
  CHANGE_BONUS_STATUS: {
    pattern: "/manager/orders/**/bonus",
    action: "PUT",
  },
  GET: {
    pattern: "/manager/orders/**/bonus",
    action: "GET",
  },
  GET_APPROVER: {
    pattern: "/manager/orders/**/approver",
    action: "GET",
  },
});

export default OrdersBonusPermissionEnum;
