import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { currencyToNumber, onlyUnique } from "~/helpers/utils/Functions";
import CategoryModel from "~/models/CategoryModel";
import ProductModel from "~/models/ProductModel";
import FileModel from "~/models/FileModel";
import { ProductTypeEnum } from "~/helpers/utils/enums/ProductTypeEnum";
import SupplierModel from "~/models/SupplierModel";
import CategoryAPI from "../services/CategoryAPI";
import ProductAPI from "../services/ProductAPI";
import UploadAPI from "../services/UploadAPI";
import { generateTreeUuid } from "../helpers/utils/Functions";

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  product: undefined,
  valuePropertie: undefined,
  products: [],
  properties: [],
  listProperties: [],
  classes: [],
  pricings: [],
  defaultCategoryProduct: [],
  getFiles: [],
  applications: [],
  suppliers: undefined,
  tabType: ProductTypeEnum.MODEL,
  validateTypeProperties: undefined,
  prodOrigens: [],
  deleteFileList: [],
  productFileToAdd: null,
  productFileToDelete: null,
  productImageFiles: [],
  productImageFilesDelete: [],
  productImageFilesUrl: [],
  productImageFilesUrlDelete: [],
  loadingexport: false,
  massImportResult: [],
};
class ProductStore {
  totalPages = 0;
  page = 0;
  size = 15;
  sort = "name";
  filter = "";
  categoriesRemove = [];
  categoriesAdd = [];
  variations = [];
  princingsRemove = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      ...initValues,
    });
  }

  reset() {
    this.product = null;
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 15;
    this.sort = "created,desc";
    this.filter = "";
    this.categoriesRemove = [];
    this.categoriesAdd = [];
    this.variations = [];
    this.princingsRemove = [];
    this.applications = [];
    this.deleteFileList = [];
    this.productFileToAdd = null;
    this.productFileToDelete = null;
    this.productImageFiles = [];
    this.productImageFilesDelete = [];
    this.productImageFilesUrl = [];
    this.productImageFilesDeleteUrl = [];
    this.massImportResult = [];
    // this.propertiesStore.reset();
  }

  resetListing() {
    this.page = 0;
  }

  /**Métodos referente a versão com variação */

  /**Finalizou os métodos referente a versão com variação */

  /**Retorna instancia da notificationStore  */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Retorna instancia da propertiesStore */
  get propertiesStore() {
    // return this.rootStore.propertiesStore;
  }

  createNewPrices() {
    let price = { price: 0 };
    return [price];
  }

  createNewStock() {
    let stock = { total: 0 };
    return [stock];
  }

  /**Cria novo produto */
  createEmptyProduct(isParent) {
    this.product = undefined;
    this.product = new ProductModel();
    this.product.active = false;
    this.product.parent = isParent;
  }

  /**Atualiza propriedades do produto. */
  async onChange(prop, value) {
    const product = this.product;
    switch (prop) {
      case "name":
        product.name = value;
        if (!product.shortName) product.shortName = value;
        break;
      case "baseProduct":
        product.baseProduct = { uuid: value.value };
        break;
      case "color":
        product.color = { uuid: value.value };
        break;
      case "size":
        product.size = { uuid: value.value };
        break;
      case "description":
        product.description = value;
        break;
      case "supplier":
        product.setSupplier({
          uuid: value.value,
        });
        break;
      case "prices":
        if (product.prices.length > 0) {
          product.prices[0].price = currencyToNumber(value);
        } else if (product.prices.length === 0) {
          product.prices.push({
            price: currencyToNumber(value),
          });
        }
        break;
      case "fakePrice":
        if (product.prices.length > 0) {
          product.prices[0].fakePrice = currencyToNumber(value);
          product.fakePrice = currencyToNumber(value);
        } else if (product.prices.length === 0) {
          product.prices.push({
            fakePrice: currencyToNumber(value),
          });
          product.fakePrice = currencyToNumber(value);
        }
        break;
      case "stocks":
        product.valueStock = currencyToNumber(value);
        break;
      case "absoluteDiscount":
      case "quantityStockEmail":
      case "percentualDiscount":
      case "weight":
      case "height":
      case "width":
      case "color":
      case "size":
      case "length":
      case "shippingWeight":
      case "shippingHeight":
      case "shippingWidth":
      case "shippingLength":
      case "committedStock":
      case "securityStock":
      case "deliveryTime":
        const number = currencyToNumber(value);
        number > 0 ? (product[prop] = number) : (product[prop] = 0);
        break;
      default:
        product[prop] = value;
    }
    this.product = new ProductModel(product);
  }

  async createByCSV(file) {
    this.loading = true;
    const response = await ProductAPI.createByCSV(file);
    this.loading = false;

    if (response?.error) {
      this.toastHelper.notify(
        STATUS_HELPER.WARNING,
        "Alguns produtos não foram salvas com sucesso. Verifique a lista de erros e tente novamente.",
        10000
      );
      this.massImportResult = response.error;
    } else {
      this.massImportResult = response;
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Produtos salvos com sucesso!"
      );
    }
  }

  async updateByCSV(file) {
    this.loading = true;
    const response = await ProductAPI.updateByCSV(file);
    this.loading = false;

    if (response?.error) {
      this.toastHelper.notify(
        STATUS_HELPER.WARNING,
        "Alguns produtos não foram atualizadas com sucesso. Verifique a lista de erros e tente novamente.",
        10000
      );
      this.massImportResult = response.error;
    } else {
      this.massImportResult = response;
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Produtos atualizados com sucesso!"
      );
    }
  }

  async createPriceByCSV(file) {
    this.loading = true;
    const response = await ProductAPI.createPriceByCSV(file);
    this.loading = false;

    if (response?.error) {
      this.toastHelper.notify(
        STATUS_HELPER.WARNING,
        'Alguns preços não foram salvas com sucesso. Verifique a lista de erros e tente novamente.',
        10000,
      );
      this.massImportResult = response.error;
    } else {
      this.massImportResult = response
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Preços salvos com sucesso!');
    }
  }

  /**Manipula lista de categorias selecionadas ou descelecionadas */
  setCategories(selecteds) {
    this.categoriesRemove = this.product.categories.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.categoriesAdd = selecteds.filter(
      (cat) => !this.product.categories.some((mCat) => mCat.uuid === cat.uuid)
    );
    const output = [...this.product.categories, ...this.categoriesAdd]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);

    this.defaultCategoryProduct = generateTreeUuid(selecteds);
    return output;
  }

  addSuppl(data) {
    this.product.supplier = new SupplierModel({
      uuid: data.value,
    });
  }

  removeAppl(uuid) {
    this.product.applications = this.product.applications.filter(
      (m) => m.uuid !== uuid
    );
    this.applications = this.changeToApplicationsSelect(
      this.product.applications
    );
  }

  // /**Adiciona imagem ao produto */
  // handleAddProductImage(files) {
  //   if (!this.product) this.createEmptyProduct();

  //   for (let index = 0; index < files.length; index++) {
  //     const file = files[index];

  //     const ordering =
  //       this.product.files.length > 0 ? this.product.files.length - 1 : 0;

  //     const newFileItem = new FileModel({
  //       file,
  //       ...file,
  //       metaTags: ["card"],

  //       size: 1,
  //       ordering,
  //     });
  //     this.product.files.push(newFileItem);
  //   }
  // }

  valueProperties(data) {
    this.valuePropertie = data;
    if (data.item.length > 0) {
      this.validateTypeProperties = "Boolean";
      this.listProperties = data.item.map((item) => {
        return { label: item.displayString, value: item.uuid, item: item };
      });
    } else {
      this.validateTypeProperties = "Text";
      this.listProperties = [];
    }
  }

  handleDeleteProductImage(fileRemove) {
    this.product.files = this.product.files.filter(
      (file) => file !== fileRemove
    );

    if (fileRemove?.uuid) this.deleteFileList.push(fileRemove);
    return this.product.files;
  }

  setProductFiles(files) {
    this.product.files = files;
  }

  // /**Envia arquivo de proposta que foi anexo */
  // async sendFiles(productUuid, files) {
  //   const newFiles = files.filter((file) => !file.uuid);
  //   const updateds = files.filter((file) => file.uuid);

  //   newFiles.length > 0 && (await this.sendNewFiles(newFiles, productUuid));
  //   updateds.length > 0 && (await this.updateFiles(updateds));
  //   this.deleteFileList.length > 0 &&
  //     (await this.deleteFiles(this.deleteFileList));
  // }

  // handleRemoveImageVariation(index) {
  //   const product = this.product;
  //   const files = [];

  //   product.files.map((f, i) => {
  //     if (index !== i) {
  //       files.push(f);
  //     }
  //   });

  //   product.files = files.map((f, i) => ({
  //     ...f,
  //     metaTags: i === 0 ? ["card"] : [`carousel-${i}`],
  //   }));

  //   this.product = new ProductModel(product);
  // }

  async addPricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.addProcuctPricing(
      this.product.uuid,
      propertyGroup
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getParentProducts(name) {
    const search = `name;${name}`;
    const response = await ProductAPI.list({ search }, ProductTypeEnum.MODEL);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response.content;
  }

  /**Vincula princig no produto */
  async removePricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.removeProcuctPricing(
      this.product.uuid,
      propertyGroup.uuid
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 15, sort = "created,desc") {
    this.page = numPage;
    this.size = size;
    this.sort = sort;
    this.tabsPage = this.tabType ? this.tabType : ProductTypeEnum.MODEL;

    await this.getListByType(this.tabType);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getListByType(this.tabType);
  }

  /**Salva um novo produto */
  async onCreate() {
    this.loading = true;
    if (this.product.part) {
      this.product.itemsCombo = [];
    }
    if (this.product.files.length === 0 || this.product?.prices?.length === 0) {
      this.product.active = false;
    }

    let copyProduct = this.product;

    this.product.stocks.push({ total: this.product.valueStock });
    const data = this.product;
    delete data.files;
    // data.merchant = this.merchant;
    let response = await ProductAPI.save(data);
    if (!response.error) {
      const product = new ProductModel(response.data);
      this.product = product;
      if (this.product.baseProduct != null) {
        await this.setProductBaseCategoriesToVariation();
      } else {
        await this.spreadProductBaseCategoriesToProductVariations();
      }

      await this.sendFiles(product.uuid);
      await this.sendImagesUrl(product.uuid);
      await this.sendImages(product.uuid);
      this.createEmptyProduct();
      this.toastHelper.notify(STATUS_HELPER.INFO, "Produto Cadastrado");
    } else {
      const originalMessage = response?.result?.response?.data;
      if (
        response?.result?.response?.data.includes(`409 Código SKU já existente`)
      ) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, originalMessage);
      } else {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          response?.result?.response?.data?.message
        );
      }
      this.product = copyProduct;
    }
    this.loading = false;
    return response;
  }

  /**Envia propriedades */
  async handlePricings(productUuid) {
    const groups = [];

    if (this.propertiesStore?.classesSelecteds && this.propertiesStore?.classesSelecteds.length > 0) {
      this.propertiesStore.classesSelecteds.map((cls) =>
      groups.push(...cls.groups)
    );
    }

    //Pega todos os princings ativos ( > 0)
    const arrayAdd = [];
    let arrayRemoveds = [];

    //Se grupos forem  > 0 verifica o que é novo e o que foi removido.
    if (groups.length > 0) {
      groups.map((pricing) => {
        if (pricing.priceIncrease > 0) arrayAdd.push(pricing);
        else if (pricing.uuid && pricing.priceIncrease <= 0)
          arrayRemoveds.push(pricing);
        return pricing;
      });
      //Verifica variações que foram removidas.
      arrayRemoveds = [
        ...arrayRemoveds,
        ...this.variations.filter(
          (variation) => !groups.some((group) => group.uuid === variation.uuid)
        ),
      ];
    }
    //Senão todas as variações foram deletadas.
    else arrayRemoveds = this.variations;
    let promises = [];
    promises.push(
      arrayAdd.map(
        async (property) =>
          await ProductAPI.addProcuctPricing(productUuid, property)
      )
    );
    promises.push(
      arrayRemoveds.map(
        async (pricing) =>
          await ProductAPI.removeProcuctPricing(productUuid, pricing.uuid)
      )
    );

    promises.length > 0 &&
      (await this.handlePromises(promises, "Falha ao desvincular categorias"));
  }

  /**Vincula produtos Variacao e produtoBase em categorias. */
  async spreadProductBaseCategoriesToProductVariations() {
    let promises = [];

    let relatedProducts;

    relatedProducts = [this.product, ...this.product.productVariations];

    if (this.categoriesAdd.length > 0) {
      promises = this.categoriesAdd.map(
        async (category) =>
          await CategoryAPI.addProductsCategory(
            category.uuid,
            relatedProducts.map((item) => {
              return { uuid: item.uuid };
            })
          )
      );
    }
    if (this.categoriesRemove.length > 0) {
      promises = [
        ...promises,
        this.categoriesRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(
              category.uuid,

              relatedProducts.map((item) => {
                return { productUuid: item.uuid };
              })
            )
        ),
      ];
    }
    promises.length > 0 &&
      (await this.handlePromises(promises, "Falha ao desvincular categorias"));
  }

  /**Vincula cateogrias de produto base para o produto variacao. */
  async setProductBaseCategoriesToVariation() {
    let promises = [];

    const relatedProducts = [this.product];

    const categoriesToRemove = await ProductAPI.getCategories(
      this.product.uuid
    );

    if (categoriesToRemove.length > 0) {
      promises = [
        ...promises,
        categoriesToRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(
              category.uuid,

              relatedProducts.map((item) => {
                return { productUuid: item.uuid };
              })
            )
        ),
      ];
    }

    const categoriesToAdd = await ProductAPI.getCategories(
      this.product.baseProduct.uuid
    );

    if (categoriesToAdd.length > 0) {
      promises = categoriesToAdd.map(
        async (category) =>
          await CategoryAPI.addProductsCategory(
            category.uuid,
            relatedProducts.map((item) => {
              return { uuid: item.uuid };
            })
          )
      );
    }

    promises.length > 0 &&
      (await this.handlePromises(promises, "Falha ao desvincular categorias"));
  }

  /**Busca todos os produtos */
  async getList(data = {}) {
    this.loading = true;

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      ...data,
    };

    const response = await ProductAPI.list(params);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca todos os produtos pelo tipo*/
  async getListByType(productType) {
    this.loading = true;
    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      search: this.filter,
    };

    const response = await ProductAPI.list(params, productType);
    if (!response.error) {
      this.tabType = productType;

      this.products = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.loading = false;
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca todos os produtos pelo tipo*/
  async exportCSVByType(productType) {
    this.loading = true;
    const params = {
      // page: this.page,
      // size: this.size,
      sort: this.sort,
      search: this.filter,
    };

    const response = await ProductAPI.exportCSVByType(params, productType);
    if (!response.error) {
      this.loading = false;
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getListBySku(skuCode = this.filter) {
    this.loading = true;
    const params = {
      sku: skuCode,
    };
    const response = await ProductAPI.getProductBySku(params);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza Produto */
  async onUpdate(removeItem) {
    this.loading = true;
    delete this.product.merchant;

    if (this.product.stocks) {
      this.product.stocks[0].total = this.product.valueStock;
    } else if (this.product.stocks.length === 0) {
      this.product.stocks.push({ total: this.product.valueStock });
    }
    if (this.product.part) {
      this.product.itemsCombo = [];
    }

    const files = this.product.files;

    await this.sendFiles(this.product.uuid);
    await this.sendImagesUrl(this.product.uuid);
    await this.sendImages(this.product.uuid);

    if (removeItem) {
      this.product.baseProduct = null;
    }

    if (this.product.files.length === 0 || this.product?.prices?.length === 0) {
      this.product.active = false;
    }
    this.product.cubage = this.product.cubage;
    const response = await ProductAPI.update(this.product.uuid, this.product);

    if (!response.error) {
      this.product = new ProductModel(response.data);
      if (this.product.baseProduct != null) {
        await this.setProductBaseCategoriesToVariation();
      } else {
        await this.spreadProductBaseCategoriesToProductVariations();
      }
      await this.handlePricings(this.product.uuid);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Alterações gravadas com sucesso."
      );
      this.productImageFiles = [];
      this.productImageFilesDelete = [];
      this.productImageFilesUrl = [];
      this.productImageFilesUrlDelete = [];
    } else {
      this.product.files = files;
      this.product.categories = categories;
      this.productImageFiles = [];
      this.productImageFilesDelete = [];
      this.productImageFilesUrl = [];
      this.productImageFilesUrlDelete = [];
      this.product = this.product;

      if (response?.error.includes("Código SKU e Merchant já existente.")) {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          "Código SKU informado já existe"
        );
      } else if (
        response?.error ===
        "could not execute statement; SQL [n/a]; constraint [uk_ostq1ec3toafnjok09y9l7dox]; nested exception is org.hibernate.exception.ConstraintViolationException: could not execute statement"
      ) {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          "Nome informado já existe"
        );
      } else {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          response?.result?.response?.data?.message
        );
      }
    }

    this.loading = false;
    return response;
  }

  /**Deleta produto */
  async onDelete(uuid) {
    this.loading = true;
    const response = await ProductAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Produto deletado");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }

  /**Desassocia a variação */
  async disableVariation(variationUuid) {
    const response = await ProductAPI.disableVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Variação desabilitada");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Desassocia a variação */
  async activateVariation(variationUuid) {
    const response = await ProductAPI.activateVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Variação habilitada");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Busca categorias do produto */
  async getCategories(productUuid) {
    const response = await ProductAPI.getCategories(productUuid);
    let categories = [];
    if (!response.error) {
      categories = response.map((cat) => new CategoryModel(cat));
      this.defaultCategoryProduct = generateTreeUuid(categories);
    } else {
      this.toastHelper.notify(STATUS_HELPER.error, response.error);
    }
    return categories;
  }

  /**Busca apenas um determinado produto */
  async get(value, prop = "uuid") {
    this.loading = true;
    const response = await ProductAPI.get(prop, value);
    if (!response.error) {
      const product = new ProductModel(response);

      this.getFiles = product.files;

      if (product.parent)
        product.categories = await this.getCategories(product.uuid);
      this.product =
        product.prices.length === 0
          ? new ProductModel({
              ...product,
              prices: this.createNewPrices(),
            })
          : new ProductModel(product);

      this.product =
        this.product.stocks.length === 0
          ? new ProductModel({
              ...this.product,
              stocks: this.createNewStock(),
            })
          : new ProductModel(product);
      this.product.valueStock =
        this.product.stocks !== undefined && this.product.stocks[0]?.total;

      // this.applications = this.changeToApplicationsSelect(
      //   this.product.applications
      // );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Funcão útil paradá feedbeack sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async deleteManual(files) {
    if (files.uuid) {
      await UploadAPI.removeFileProduct(files.uuid);
    }
    this.product.files = this.product.files.filter(
      (e) => e.metaTags[0] !== "pdf"
    );
    this.get(this.product.uuid);

    this.toastHelper.notify(STATUS_HELPER.INFO, "Removido com sucesso!");
  }
  // /**Atualiza imagem da categoria */
  // async deleteFiles(files) {
  //   const promise = files.map(async (file) => {
  //     await UploadAPI.removeFileProduct(file.uuid);
  //   });
  //   return await this.handlePromises(promise, "Falha o deletar arquivo");
  // }

  // async updateFiles(updatedFiles) {
  //   const promiseUpdate = updatedFiles.map(async (file) => {
  //     this.notificationStore.addItemUpload(file);
  //     await UploadAPI.updateFile(file);
  //   });
  //   const response = await this.handlePromises(
  //     promiseUpdate,
  //     "Falha atualizar arquivos."
  //   );
  //   setTimeout(() => {
  //     updatedFiles.forEach((file) =>
  //       this.notificationStore.removeItemUpload(file)
  //     );
  //   }, 3300);
  //   return response;
  // }

  // async sendNewFiles(newFiles, productUuid) {
  //   const promiseNew = newFiles.map(async (file) => {
  //     this.notificationStore.addItemUpload(file);
  //     await UploadAPI.uploadProduct(productUuid, file);
  //   });
  //   const response = await this.handlePromises(
  //     promiseNew,
  //     "Falha anexar arquivos."
  //   );
  //   setTimeout(() => {
  //     newFiles.forEach((file) => this.notificationStore.removeItemUpload(file));
  //   }, 3300);
  //   return response;
  // }

  /**Retorna lista de categorias para uso no select */
  getListSelect(products = this.products) {
    return products.map((prd) => ({
      value: prd.uuid,
      label: `${prd.skuCode} - ${prd.name}`,
    }));
  }

  changeToApplicationsSelect(app) {
    return app.map((m) => ({
      value: m.uuid,
      label: m.getNameSelect,
    }));
  }

  async checkCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.checkCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      "Falha anexar arquivos."
    );
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async sendCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.sendCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      "Falha anexar arquivos."
    );
    if (response[1].data.sucess) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Atualizado com sucesso");
    }
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async getCsvProducts() {
    this.loading = true;
    let response = await UploadAPI.getProductsCSV();
    if (!response.error) {
      this.loading = false;
      return response.data;
    }
    this.loading = false;
    return;
  }

  // Adicionar a propriedade
  async addPropertieSelected(propertie) {
    this.loading = true;
    const merchant = this.rootStore.usersStore.userMerchant;

    const propertyClass = propertie.propertyClass;
    const propertyToPush = {
      ...propertie,
      ...{ propertyClass: { ...propertyClass, merchant } },
    };
    this.product.productProperties.push(propertyToPush);
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      "Especificação cadastrada com sucesso!"
    );
    this.loading = false;
  }

  /**Remove a propriedade selecionada */
  async removePropertieSelected(data) {
    this.loading = true;
    const filterItens = this.product.productProperties.filter(
      (item) => item.uuid !== data
    );
    this.product.productProperties = filterItens;
    this.loading = false;
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      "Especificação removido com sucesso!"
    );
  }

  /**Adiciona Parte/Produto no itemsCombo */
  addComboSelected(partProduct) {
    this.product.itemsCombo.push(partProduct);
  }

  /**Remove a Parte/Produto no itemsCombo */
  removeComboSelected(element) {
    this.loading = true;
    const itemsCombo = this.product.itemsCombo.filter(
      (e) => e.product.uuid !== element.product.uuid
    );
    this.product.itemsCombo = itemsCombo;
    this.loading = false;
  }

  //**Valida se o produto possui categoria ativa no cadastro */
  checkProductHasCategory() {
    if (this.product.categories && this.product.categories.length > 0) {
      if (this.categoriesRemove.length === this.product.categories.length) {
        if (this.categoriesAdd.length > 0) {
          return true;
        } else {
          return false;
        }
      } else return true;
    } else if (this.categoriesAdd.length > 0) {
      return true;
    }

    return false;
  }

  async getProdOrigens() {
    const response = await ProductAPI.getPordOrigens();

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
  }

  /**Retorna variações filtradas de acordo com o term */
  async getAvailableProductVariations(filter) {
    const response = await ProductAPI.getAvailableProductVariations(filter);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    else return response;
  }

  addProductVariation(product) {
    this.product.productVariations.push(product);
  }

  removeProductVariation(product) {
    this.product.productVariations.filter(
      (variation) => variation.uuid === product.uuid
    );
  }

  /**Busca apenas um determinado produto */
  async getViewPdp(value, prop = "uuid") {
    this.loading = true;
    const response = await ProductAPI.get(prop, value);
    if (!response.error) {
      const product = new ProductModel(response);

      if (product.parent)
        product.categories = await this.getCategories(product.uuid);

      this.product = new ProductModel(product);
      this.loading = false;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
    }
    return response;
  }

  /**Adiciona imagem ao produto */
  handleProductImages(file, ordering) {
    this.loading = true;
    const newFileItem = new FileModel({
      file,
      ...file,
      metaTags: ["card"],
      size: 1,
      ordering: ordering,
      path: file?.preview,
      uuid: Math.random() * 5,
    });

    this.product.files.push(newFileItem);
    this.productImageFiles.push(newFileItem);
    this.loading = false;
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      "Imagem cadastrada com sucesso!"
    );
  }

  handleRemoveImageVariation(index) {
    const product = this.product;
    const files = [];

    product.files.map((f, i) => {
      if (index !== i) {
        files.push(f);
      }
    });

    product.files = files.map((f, i) => ({
      ...f,
      metaTags: i === 0 ? ["card"] : [`carousel-${i}`],
    }));

    this.product = new ProductModel(product);
  }

  async removeImage(data) {
    const removeItem = this.product.files.filter(
      (item) => item.uuid !== data.uuid
    );

    this.product.files = removeItem;
    if (data.uuid) {
      this.productImageFilesUrlDelete.push(data);
    }

    if (this.product.files.length === 0) this.product.active = false;
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      "Imagem removida com sucesso!"
    );
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendFiles(productUuid) {
    //ok
    this.productFileToAdd &&
      (await this.sendNewFiles([this.productFileToAdd], productUuid, "manual"));
    this.productFileToDelete &&
      (await this.deleteFiles([this.productFileToDelete]));
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendImagesUrl(productUuid) {
    this.productImageFilesUrl.length > 0 &&
      (await this.sendNewFiles(this.productImageFilesUrl, productUuid, "url"));
    this.productImageFilesUrlDelete.length > 0 &&
      (await this.deleteFiles(this.productImageFilesUrlDelete));
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendImages(productUuid) {
    this.productImageFiles.length > 0 &&
      (await this.sendNewFiles(this.productImageFiles, productUuid, "image"));
    this.productImageFilesDelete.length > 0 &&
      (await this.deleteFiles(this.productImageFilesDelete));
  }

  async deleteFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.removeFileProduct(file.uuid);
    });
    return await this.handlePromises(promise, "Falha o deletar arquivo");
  }

  async updateFiles(updatedFiles) {
    const promiseUpdate = updatedFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.updateFile(file);
    });
    const response = await this.handlePromises(
      promiseUpdate,
      "Falha atualizar arquivos."
    );
    setTimeout(() => {
      updatedFiles.forEach((file) =>
        this.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  async addFile(data) {
    this.loading = true;

    const newFileItem = new FileModel({
      ...data,
      metaTags: ["pdf"],
      file: data,
      path: URL.createObjectURL(data),
    });

    // Armazena arquivo antigo
    const oldFile = this.product.file;
    if (oldFile?.uuid) this.productFilesDelete = oldFile;

    // Atribui o novo arquivo para o product
    this.product.files.push(newFileItem);
    //Adiciona o arquivo para a listagem de arquivos a serem adicionados na API
    this.productFileToAdd = newFileItem;
    this.loading = false;

    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      "Manual adicionado com sucesso!"
    );
  }

  async sendNewFiles(newFiles, productUuid, type) {
    //ok
    const promiseNew = newFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      if (type === "manual") {
        await UploadAPI.uploadProductByImageFile(productUuid, file);
      } else if (type === "image") {
        if (file.uuid.length === 36) {
          await UploadAPI.uploadProduct(productUuid, file);
        } else {
          file.uuid = undefined;
          await UploadAPI.uploadProduct(productUuid, file);
        }
      } else if (type === "url") {
        if (file.uuid.length === 36) {
          await UploadAPI.uploadProductByImageURL(productUuid, file);
        } else {
          file.uuid = undefined;
          await UploadAPI.uploadProductByImageURL(productUuid, file);
        }
      }
    });
    const response = await this.handlePromises(
      promiseNew,
      "Falha anexar arquivos."
    );
    setTimeout(() => {
      newFiles.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return response;
  }

  async addImage(data) {
    this.loading = true;
    let newFileItem = new FileModel(data);
    newFileItem.metaTags = ["url"];
    newFileItem.uuid = Math.random() * 5;
    this.product.files.push(newFileItem);
    this.productImageFilesUrl.push(newFileItem);
    this.loading = false;
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      "Imagem cadastrada com sucesso!"
    );
  }

  async getExpoxtList(
    size = 999999,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loadingexport = true;
    const params = {
      page: 0,
      size: 99999,
      sort: this.sort,
      search: this.filter,
    };

    const response = await ProductAPI.list(params, this.tabType);

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      this.loadingexport = false;
      return [];
    }
    this.loadingexport = false;
    return response.content.map((value) => new ProductModel(value));
  }
}

export default ProductStore;
