/* eslint-disable class-methods-use-this */
import { extendObservable } from "mobx";
import AccountsPermissionEnum from "~/helpers/utils/permissions/accounts";
import AuthoritiesGroupsPermissionEnum from "~/helpers/utils/permissions/authoritiesGroups";
import BannersPermissionEnum from "~/helpers/utils/permissions/banner";
import CategoriesPermissionEnum from "~/helpers/utils/permissions/categories";
import ColorsPermissionEnum from "~/helpers/utils/permissions/colors";
import ComponentsPermissionEnum from "~/helpers/utils/permissions/components";
import CustomerPermissionEnum from "~/helpers/utils/permissions/customer";
import MaterialsPermissionEnum from "~/helpers/utils/permissions/materials";
import OrdersPermissionEnum from "~/helpers/utils/permissions/order";
import ProductsPermissionEnum from "~/helpers/utils/permissions/products";
import PropertiesPermissionEnum from "~/helpers/utils/permissions/properties";
import SizesPermissionEnum from "~/helpers/utils/permissions/sizes";
import VoltagesPermissionEnum from "~/helpers/utils/permissions/voltages";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import ItemMenuModel from "~/models/ItemMenuModel";
import CampaignsPermissionEnum from "../helpers/utils/permissions/campaigns";
import TemplateEmailPermissionEnum from "../helpers/utils/permissions/emailTemplate";
import EvaluationsPermissionEnum from "../helpers/utils/permissions/evaluations";
import FilialPermissionEnum from "../helpers/utils/permissions/filial";
import NewsletterPermissionEnum from "../helpers/utils/permissions/newsletter";
import reportAbandonedCartsEnum from "../helpers/utils/permissions/reportAbandonedCarts";
import reportBestSellingCategoriesEnum from "../helpers/utils/permissions/reportBestSellingCategories";
import reportBestSellingProductsEnum from "../helpers/utils/permissions/reportBestSellingProducts";
import reportCampaignsEnum from "../helpers/utils/permissions/reportCampaigns";
import reportLog from "../helpers/utils/permissions/reportLog";
import ReportSalesGeneralPermissionEnum from "../helpers/utils/permissions/reportSalesGeneral";
import ReportTopViewCategoriesEnum from "../helpers/utils/permissions/reportTopViewCategories";
import reportVouchersEnum from "../helpers/utils/permissions/reportVouchers";
import VoucherBatchesPermissionEnum from "../helpers/utils/permissions/voucherBatches";
import RolesPermissionEnum from "../helpers/utils/permissions/roles";
import OrdersBonusPermissionEnum from "../helpers/utils/permissions/orderBonus";

class MenuStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, {
      menus: [],
      dashboard: new ItemMenuModel(this.dashboardData),
      itemMenu: undefined,
      subItemMenu: undefined,
    });
  }

  /** Carrega menu */
  loadMenu() {
    const showMenuStoom = this.rootStore.authStore.isStoomAdmin;
    let items = this.itemsMenu;

    if (!showMenuStoom) {
      items = this.itemsMenu.filter((item) => item.title !== "Stoom");
    }

    this.menus = items.map((item) => new ItemMenuModel(item));
    if (StorageUtil.getItem(KEYS.PATH_MENU)) {
      this.setCurrentMenu(StorageUtil.getItem(KEYS.PATH_MENU));
    }
  }

  /** Altera o menu atual. */
  setCurrentMenu(path) {
    StorageUtil.setItem(KEYS.PATH_MENU, path);
    this.itemMenu = this.menus.find((item) => {
      const sub = item.subItems.find((subItem) => subItem.path === path);
      if (sub) {
        this.subItemMenu = sub;
        return item;
      }
      return null;
    });
  }

  /** Retorna o titulo do menu selecionado */
  get title() {
    if (!this.itemMenu) return this.dashboard.title;
    return this.itemMenu && this.itemMenu.title;
  }

  /** Retorna o subtitulo do submenu */
  get subtitle() {
    if (!this.subItemMenu) return this.dashboard.subtitle;
    return this.subItemMenu && this.subItemMenu.title;
  }

  /** Retorna se dashboard está visible */
  get dashboardVisible() {
    return !this.itemMenu;
  }

  /** Dados da dashboard */
  get dashboardData() {
    return {
      title: "Dashboard",
      subtitle: "Um painel completo para controlar seu site",
      icon: "fas fa-home",
      subItems: [],
    };
  }

  /** Items de menu */
  get itemsMenu() {
    return Object.values({
      clientes: {
        title: "Clientes",
        icon: "fas fa-users",
        permissions: Object.values(CustomerPermissionEnum),
        subItems: [
          {
            path: "/clientes",
            title: "Clientes",
            permissions: Object.values(CustomerPermissionEnum),
          },
          {
            path: "/importar-clientes-em-massa",
            title: "Cadastro em massa",
            // permissions: [CustomerPermissionEnum.CSV_CREATE],
            permissions: Object.values(CustomerPermissionEnum),
          },
        ],
      },
      usuarios: {
        title: "Administradores",
        subtitle: "Área de administração de Usúarios",
        icon: "fas fa-user-shield",
        permissions: Object.values(AccountsPermissionEnum).concat(
          Object.values(AuthoritiesGroupsPermissionEnum)
        ),
        subItems: [
          {
            path: "/lista-perfil",
            title: "Perfis",
            permissions: Object.values(AuthoritiesGroupsPermissionEnum),
          },
          {
            path: "/lista-usuarios",
            title: "Administradores",
            permissions: Object.values(AccountsPermissionEnum),
          },
          {
            path: "/cargos",
            title: "Cargos",
            permissions: Object.values(RolesPermissionEnum),
          },
        ],
      },
      config: {
        title: "Configuração Loja",
        subtitle: "Cadastro de configuração",
        icon: "fas fa-light fa-cog",
        permissions: Object.values(BannersPermissionEnum),
        subItems: [
          {
            path: "/banners",
            title: "Banners",
            permissions: Object.values(BannersPermissionEnum),
          },
          {
            path: "/home-dinamica",
            title: "Home Dinâmica",
            permissions: Object.values(ComponentsPermissionEnum),
          },
          {
            path: "/configuracoes-loja",
            title: "Configurações Loja",
            permissions: Object.values(ComponentsPermissionEnum),
          },
          {
            path: "/institucional",
            title: "Institucional",
            permissions: Object.values(ComponentsPermissionEnum),
          },
        ],
      },
      filiais: {
        title: "Filiais",
        icon: "fas fa-light fa-suitcase",
        permissions: Object.values(FilialPermissionEnum),
        subItems: [
          {
            path: "/filiais",
            title: "Filiais",
            permissions: Object.values(FilialPermissionEnum),
          },
        ],
      },
      categorias: {
        title: "Categorias",
        icon: "fas fa-th",
        permissions: Object.values(CategoriesPermissionEnum),
        subItems: [
          {
            path: "/categorias",
            title: "Categorias",
            permissions: Object.values(CategoriesPermissionEnum),
          },
        ],
      },
      order: {
        title: "Pedidos",
        subtitle: "Pedidos",
        icon: "fas fa-light fa-home",
        permissions: Object.values(OrdersPermissionEnum).concat(Object.values(OrdersBonusPermissionEnum)),
        subItems: [
          {
            path: "/pedidos",
            title: "Todos Pedidos",
            permissions: Object.values(OrdersPermissionEnum),
          },
          {
            path: "/pedidos-bonificacao",
            title: "Pedidos Tipo Bonificação",
            permissions: [OrdersBonusPermissionEnum.GET]
          },
        ],
      },
      // shipping: {
      //   title: "Fretes",
      //   subtitle: "Fretes",
      //   icon: "fas fa-light fa-truck",
      //   permissions: Object.values(ShippingPermissionEnum),
      //   subItems: [
      //     {
      //       path: "/fretes",
      //       title: "Fretes",
      //       permissions: Object.values(ShippingPermissionEnum),
      //     },
      //     {
      //       path: "/upload-frete",
      //       title: "Upload Frete",
      //       permissions: Object.values(ShippingPermissionEnum),
      //     },
      //   ],
      // },
      promotion: {
        title: "Promoções",
        subtitle: "Promoções",
        icon: "fa fa-percent",
        permissions: Object.values(CampaignsPermissionEnum).concat(
          Object.values(VoucherBatchesPermissionEnum)
        ),
        subItems: [
          {
            path: "/campanhas",
            title: "Campanhas",
            permissions: Object.values(CampaignsPermissionEnum),
          },
          {
            path: "/cupons",
            title: "Cupons",
            permissions: Object.values(VoucherBatchesPermissionEnum),
          },
        ],
      },
      produtos: {
        title: "Produtos",
        icon: "fab fa-product-hunt",
        permissions: Object.values(ProductsPermissionEnum)
          .concat(Object.values(ColorsPermissionEnum))
          .concat(Object.values(SizesPermissionEnum))
          .concat(Object.values(VoltagesPermissionEnum))
          .concat(Object.values(PropertiesPermissionEnum))
          .concat(Object.values(EvaluationsPermissionEnum))
          .concat(Object.values(MaterialsPermissionEnum)),
        subItems: [
          {
            path: "/produtos",
            title: "Produtos",
            permissions: Object.values(ProductsPermissionEnum),
          },
          {
            path: "/cadastrar-variacao-produtos",
            title: "Produto Variação",
            permissions: Object.values(ProductsPermissionEnum),
          },
          {
            path: "/cores",
            title: "Cores",
            permissions: Object.values(ColorsPermissionEnum),
          },
          {
            path: "/tamanhos",
            title: "Tamanhos",
            permissions: Object.values(SizesPermissionEnum),
          },
          {
            path: "/voltagem",
            title: "Voltagens",
            permissions: Object.values(VoltagesPermissionEnum),
          },
          {
            path: "/material",
            title: "Material",
            permissions: Object.values(MaterialsPermissionEnum),
          },
          {
            path: "/gerenciar-avaliacoes",
            title: "Avaliações",
            permissions: Object.values(EvaluationsPermissionEnum),
          },
          {
            path: "/propriedades",
            title: "Especificações",
            permissions: Object.values(PropertiesPermissionEnum),
          },
        ],
      },
      produtos: {
        title: "Produtos",
        icon: "fab fa-product-hunt",
        permissions: Object.values(ProductsPermissionEnum)
          .concat(Object.values(PropertiesPermissionEnum))
          .concat(Object.values(EvaluationsPermissionEnum)),
        subItems: [
          {
            path: "/produtos",
            title: "Produtos",
            permissions: Object.values(ProductsPermissionEnum),
          },
          {
            path: "/inclusao-produtos-em-massa",
            title: "Cadastro em Massa",
            permissions: Object.values(ProductsPermissionEnum),
          },
          {
            path: "/edicao-produtos-em-massa",
            title: "Edição em Massa",
            permissions: Object.values(ProductsPermissionEnum),
          },
          // {
          //   path: "/inclusao-preco-em-massa",
          //   title: "Inclusão de Preço em Massa",
          //   permissions: Object.values(ProductsPermissionEnum),
          // },
          {
            path: "/cadastrar-variacao-produtos",
            title: "Produto Variação",
            permissions: Object.values(ProductsPermissionEnum),
          },
          {
            path: "/gerenciar-comentarios",
            title: "Avaliações",
            permissions: Object.values(EvaluationsPermissionEnum),
          },
          {
            path: "/propriedades",
            title: "Especificações",
            permissions: Object.values(PropertiesPermissionEnum),
          },
        ],
      },
      // installments: {
      //   title: "Condições de Pagamento",
      //   subtitle: "Condições de Pagamento",
      //   icon: "fas fa-solid fa-credit-card",
      //   permissions: Object.values(reportVouchersEnum),
      //   subItems: [
      //     {
      //       path: "/condicoes-parcelamento",
      //       title: "Configurar parcelas",
      //       permissions: Object.values(reportVouchersEnum),
      //     },
      //   ],
      // },
      sales: {
        title: "Relatórios",
        subtitle: "Um painel completo para controlar seu site",
        icon: "fas fa-clone",
        permissions: Object.values(reportVouchersEnum)
          .concat(Object.values(reportCampaignsEnum))
          .concat(Object.values(reportBestSellingCategoriesEnum))
          .concat(Object.values(ReportSalesGeneralPermissionEnum))
          .concat(Object.values(reportBestSellingProductsEnum))
          .concat(Object.values(ReportTopViewCategoriesEnum))
          .concat(Object.values(reportAbandonedCartsEnum))
          .concat(Object.values(reportLog))
          .concat(Object.values(EvaluationsPermissionEnum)),
        subItems: [
          {
            path: "/vendas/general",
            title: "Visão geral",
            permissions: Object.values(ReportSalesGeneralPermissionEnum),
          },
          {
            path: "/vendas/carrinho-abandonado",
            title: "Carrinho abandonado",
            permissions: Object.values(reportAbandonedCartsEnum),
          },
          {
            path: "/vendas/produto",
            title: "Vendas por produto",
            permissions: Object.values(reportBestSellingProductsEnum),
          },
          {
            path: "/vendas/produtos-mais-vistos",
            title: "Produtos mais vistos",
            permissions: Object.values(ReportTopViewCategoriesEnum),
          },
          {
            path: "/vendas/avaliacao-produtos",
            title: "Avaliação por produto",
            permissions: Object.values(EvaluationsPermissionEnum),
          },
          {
            path: "/vendas/categorias",
            title: "Vendas por Categoria",
            permissions: Object.values(reportBestSellingCategoriesEnum),
          },
          {
            path: "/vendas/campanha",
            title: "Campanha",
            permissions: Object.values(reportCampaignsEnum),
          },
          {
            path: "/vendas/cupom",
            title: "Cupom",
            permissions: Object.values(reportVouchersEnum),
          },
          {
            path: "/vendas/logs",
            title: "Log de Acesso",
            permissions: Object.values(reportLog),
          },
        ],
      },
      templates: {
        title: "Templates e-mail",
        subtitle: "Área de templates de e-mail",
        icon: "fas fa-light fa-envelope",
        permissions: Object.values(TemplateEmailPermissionEnum),
        subItems: [
          {
            path: "/lista-templates",
            title: "Lista de Templates",
            permissions: Object.values(TemplateEmailPermissionEnum),
          },
          {
            path: "/newsletters",
            title: "Newsletter",
            permissions: Object.values(NewsletterPermissionEnum),
          },
        ],
      },
      // logistica: {
      //   title: "Logistica",
      //   subtitle: "Logistica",
      //   icon: "fas fa-truck",
      //   permissions: Object.values(NewsletterPermissionEnum),
      //   subItems: [
      //     {
      //       path: "/transportadoras",
      //       title: "Transportadoras",
      //       permissions: Object.values(NewsletterPermissionEnum),
      //     },
      //     {
      //       path: "/faixas-frete",
      //       title: "Faixas De Frete",
      //       permissions: Object.values(NewsletterPermissionEnum),
      //     },
      //   ],
      // },
    });
  }
}

export default MenuStore;
