import { extendObservable } from "mobx";
import API from "../services/OrderAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import OrderModel from "~/models/OrderModel";

class OrderStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      order: undefined,
      orders: [],
      ordersCustomer: [],
      bosnusOrderApprover: {},
      channelsListSelect: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      totalPagesCustomer: 0,
      size: 15,
      sizeCustomer: 15,
      filters: {},
      page: 0,
      pageCustomer: 0,
      sort: "created,desc",
    });
  }

  reset() {
    this.order = undefined;
    this.orders = [];
    this.bosnusOrderApprover = {};
    this.totalPages = 0;
    this.size = 15;
    this.number = 0;
    this.sort = "created,desc";
    this.filters = {};
    this.loading = false;
  }

  /**Atualiza um novo registror*/
  async onChange(prop, value) {
    const _channel = this.order ? this.order : new OrderModel();
    switch (prop) {
      default:
        _channel[prop] = value;
    }
    this.order = new OrderModel(_channel);
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 15, sort) {
    this.page = numPage;
    this.size = size;
    sort ? (this.sort = sort) : "";

    await this.getList(size);
    return true;
  }

  /**Busca todos os registros */
  async getList(
    size = 15,
    page = this.page,
    sort = this.sort,
    filters = this.filters,
    isBonus = false,
  ) {
    this.loading = true;
    let response
    if(isBonus) {
      response = await API.getListOrderBonus({
        size,
        page,
        sort,
        ...filters,
      });
    } else {
      response = await API.getList({
        size,
        page,
        sort,
        ...filters,
      });
    }

    this.loading = false;
    if (response.error) return [];
    this.orders = response.content.map((usr) => new OrderModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await API.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new OrderModel(usr));
  }

  /**
   * Busca todas as informações de um registro.
   * @param  {string} uuid
   */
  async get(uuid, isBonus) {
    this.loading = true;
    let response;
    if (isBonus) {
      response = await API.getOrderBonus(uuid);
    } else {
      response = await API.get(uuid);
    }
    this.loading = false;
    if (!response.error) {
      this.order = new OrderModel(response);
      return this.order;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async getByCustomer(uuid, size = 15, page = 0, sort = "idOrder") {
    const response = await API.getByCustomer(uuid, {
      size,
      page,
      sort,
    });

    if (response.error) return [];
    this.ordersCustomer = response.content.map((usr) => new OrderModel(usr));
    this.totalPagesCustomer = response.totalPages;
    this.pageCustomer = response.number;
  }

  async getByCustomerExport(uuid, size = 1000) {
    const response = await API.getByCustomer(uuid, {
      size,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new OrderModel(usr));
  }

  async setFilters(page, filters, isBonus) {
    this.filters = filters;
    await this.getList(this.size, page, this.sort, filters, isBonus);
  }

  async changeOrderStatus(orderUuid, customerUuid, status) {
    const response = await API.changeOrderStatus(
      orderUuid,
      customerUuid,
      status
    );
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Status alterado com sucesso"
      );
    } else
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Falha ao alterar status do pedido"
      );
  }

  async changeBonusStatus(orderUuid, status) {
    this.loading = true;
    const response = await API.changeBonusStatus(
      orderUuid,
      status
    );
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Status alterado com sucesso"
      );
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Falha ao alterar status do pedido"
      );
    }
    this.loading = false;
    this.setFilters(0, { bonus:  true }, true)
  }

  async getBonusOrderApprover(orderUuid) {
    this.loading = true;
    const response = await API.getBonusOrderApprover(
      orderUuid,
    );
    if (response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Erro ao buscar quem aprovou/reprovou o pedido tipo bônus"
      );
    } else {
      this.bosnusOrderApprover = response;
    }
    this.loading = false;
  }
}
export default OrderStore;
