import BaseAPI, { URLS } from "./BaseAPI";

class OrderAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ORDER}/custom/search`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de pedidos" };
    } catch (e) {
      return { error: "Falha ao carregar a lista de pedidos" };
    }
  }

  static async getListOrderBonus(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ORDER}/search/bonus`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de pedidos" };
    } catch (e) {
      return { error: "Falha ao carregar a lista de pedidos" };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.ORDER, data);
      return response.status === 201
        ? response.data
        : { error: "Falha ao salvar pedido" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar pedido",
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ORDER}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar pedido" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar pedido",
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORDER}/${uuid}/customers/order`
      );
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar pedido" };
    } catch (e) {
      return { error: "Falha ao carregar pedido" };
    }
  }

  static async getOrderBonus(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORDER}/${uuid}/bonus`
      );
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar pedido" };
    } catch (e) {
      return { error: "Falha ao carregar pedido" };
    }
  }

  static async getBonusOrderApprover(order_uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORDER}/${order_uuid}/approver`
      );
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar pedido" };
    } catch (e) {
      return { error: "Falha ao carregar pedido" };
    }
  }

  static async changeOrderStatus(orderUuid, customerUuid, status) {
    try {
      const response = await BaseAPI.put(
        `${URLS.ORDER}/${orderUuid}/status/customers/${customerUuid}`,
        status
      );
      return response.status === 200
        ? response.data
        : { error: "Falha ao alterar status do pedido" };
    } catch (e) {
      return { error: "Falha ao alterar status do pedido" };
    }
  }

  static async getByCustomer(customerUuid, params) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORDER}/customers/${customerUuid}/`,
        params
      );

      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar pedido" };
    } catch (e) {
      return { error: "Falha ao carregar pedido" };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.ORDER}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: "Falha ao deletar pedido" };
    } catch (e) {
      return { error: "Falha ao deletar pedido" };
    }
  }

  static async changeBonusStatus(uuid, status) {
    try {
      const response = await BaseAPI.put(`${URLS.ORDER}/${uuid}/bonus?bonusStatus=${status}`);
      return response.status === 204
        ? response.data
        : { error: "Falha ao deletar pedido" };
    } catch (e) {
      return { error: "Falha ao deletar pedido" };
    }
  }
}

export default OrderAPI;
